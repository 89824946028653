<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="thankyousessions">
    <div class="container_thankyousession" >
      <h1>Thank you!</h1>
      <p v-if="!finish">
        We hope you found the webinar insightful and gained
        a deeper understanding of LGBTQ+ allyship.
        Please RSVP for one of our three upcoming
        live sessions to further explore this important topic.
      </p>
      <p v-else>
        You have completed phase one of Absolut Ally Certification.
        We will notify you via email when our next live session is scheduled.
        Our sessions are typically available monthly.
      </p>
    </div>
    <div class="container_sessions" v-if="!finish">
      <div class="item_session b-rigth">
        <h3>Live Session 1</h3>
        <p>
          Tuesday, August 29, 2023
         <br/>
         11:30 a.m. - 1:00 p.m. (EST)
        </p>
        <button @click="selectedRSVP(1)">RSVP</button>
      </div>

      <hr>

      <div class="item_session">
        <h3>Live Session 2</h3>
        <p>
          Tuesday, August 29, 2023
          <br/>
          2:00 p.m. - 3:30 p.m. (EST)
        </p>
        <button @click="selectedRSVP(2)">RSVP</button>
      </div>

      <hr>

      <div class="item_session b-left">
        <h3>Live Session 3</h3>
        <p>
          Wednesday, August 30, 2023
          <br/>
          3:30 p.m. - 5:00 p.m. (EST)
        </p>
        <button @click="selectedRSVP(3)">RSVP</button>
      </div>
    </div>

  </section>
</template>

<script>
import axios from 'axios';

export default {
  components: {

  },
  data() {
    return {
      finish: true,
    };
  },
  computed: {

  },
  methods: {
    async selectedRSVP(id) {
      const gAnalitycs = {
        value: `Click_RSVP_S${id}`,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      const rsvp = localStorage.getItem('safebarsrsvp');
      // eslint-disable-next-line no-unused-vars
      await axios.post(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/session/${id}`, {}).then((_response) => {
        localStorage.setItem('safebarssession', id);
        this.$emit('changeStep', 5);
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  mounted() {
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#thankyousessions{
  hr {
    width: 50%;
    color: #AAAAAE;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  .container_thankyousession{
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }

    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web' !important;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 698px;
      margin: 0 auto;
      padding-bottom: 40px;

      @media screen and (max-width: 600px) {
            font-size: 18px;
          }
    }
    button{
      width: 348px;
      height:62px;
      background-color: #150075;

      color: #FFF;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;

      @media screen and (max-width: 600px) {
            font-size: 22px;
          }
    }
  }
  .container_sessions{
    background: #C6EEFA;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .item_session{
      padding: 78px;
      @media screen and (max-width: 600px) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      h3{
        color: #150075;
        text-align: center;
        font-family: Absolut Handwritten Web;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
      }

      p{
        color: #252525;
        text-align: center;
        font-family: 'Absolut Sans 2021' !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }
      button{
        width: 233px;
        height: 58px;
        background: #150075;

        color: #FFF;
        text-align: center;
        font-family: 'Absolut Sans 2021' !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        text-transform: uppercase;
        cursor: pointer;
        :hover{
          opacity: 0.8;
        }
      }
      &.b-rigth{
        //border-right: 1px solid #AAAAAE;
      }
      &.b-left{
        //border-left: 1px solid #AAAAAE;
      }
    }
  }
}
</style>
