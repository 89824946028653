import { createVuestic } from 'vuestic-ui';
import VueGtag from 'vue-gtag';
import wrapper from 'vue3-webcomponent-wrapper';
// import defineCustomElement from './defineCustomElementWithStyles';
import { createApp, h } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

import AppRoot from '@/AppRoot.vue';
// import VueMask from 'v-mask';
import store from './store';
import router from './router';

// import 'element-plus/dist/index.css';

// customElements.define(
//   'debrain-webinar-form',
//   defineCustomElement(AppRoot, {
//     plugins: [store, router],
//   }),
// );

function caw(...args) {
  console.log(...args);
  const app = createApp(...args);
  const v = createVuestic();
  // console.log(v);
  app.use(v);
  app.use(store);
  app.use(router);
  app.use(VueSweetalert2);
  app.use(VueGtag, {
    includes: [
      { id: 'G-1881F45WY2' },
      // { id: 'G-M1MVME2FZC' },
    ],
    config: { id: 'G-3103016390' },
  });
  // app.use(VueMask);
  return app;
}

const webComponent = wrapper(AppRoot, caw, h);
window.customElements.define('debrain-webinar-form', webComponent);
