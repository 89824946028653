<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="webinar_section">
    <div class="container_webinar" >
      <h1>ABSOLUT ALLY: SAFE BARS WEBINAR</h1>
      <p>
        We’re happy you’re here.
        Ready to learn more about being a better ally
        and how to create welcoming environments for all?
        Let’s get started.
        <br/>
        <br/>
        <b>
        Please note:
        You must watch the entire webinar to move to
        the next step.
        </b>
      </p>
    </div>

  </section>
  <BannerVideoSection
      video="https://player.vimeo.com/video/838704499?h=8063e0fd34&autoplay=0&title=0&byline=0&portrait=0"
      :text="true"
       isYoutube="true"
       :icon="false"
       :iconMobile="false"
       @ended="endedVideo"
  />

</template>

<script>
import BannerVideoSection from '@/components/BannerVideoSection.vue';

export default {
  components: {
    BannerVideoSection,

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {
    endedVideo() {
      const gAnalitycs = {
        value: 'Click_Watch_Webinar',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      this.$emit('changeStep', 4);
    },
  },
  mounted() {
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#webinar_section{
  max-width: 844px;
  margin: 0 auto;

  .plyr__controls, .plyr__control {
    display: none !important;
  }
  .youtube-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    border: 0;
    iframe, video, object {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      border: 0;
    }
  }

  .container_webinar{
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }

    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      max-width: 570px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: Absolut Sans 2021;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 763px;
      margin: 0 auto;
      padding-bottom: 40px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    button{
      width: 348px;
      height:62px;
      background-color: #150075;

      color: #FFF;
      text-align: center;
      font-family: Absolut Sans 2021;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
}
</style>
