<template>
  <div id="appDEBRAIN">
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/network">Network</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</div>
</template>

<script>
// eslint-disable vue/no-unused-components
import { mapActions } from 'vuex';
import HomeView from '@/views/HomeView.vue';
import API from '../utils/api';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HomeView,
  },
  data() {
    return {
      deferredPrompt: null,
      showInstallPrompt: false,
    };
  },
  methods: {
    ...mapActions(['updateIP']),
    registerFonts() {
      const css = `
      @font-face {
        font-family: 'Absolut Sans 2021';
        src: url("https://plant.hellosandia.com/fonts/AbsolutSans2021-Regular.otf") format("opentype");
      }

      @font-face {
        font-family: 'Absolut Handwritten Web';
        src: url('https://plant.hellosandia.com/fonts/AbsolutHandwrittenHeadline2021-Regular.otf') format('opentype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      `;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.id = 'fontdef';
      style.type = 'text/css';
      head.appendChild(style);
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
    async testAPI() {
      try {
        const { data: { signature } } = await API.get('/signature/1232123');
        console.log('🚀 ~ file: AppRoot.vue:61 ~ testAPI ~ signature:', signature);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    // this.updateIP();
    this.registerFonts();
  },
  mounted() {
    console.log('mounted');
    this.$router.push({ name: 'home' });
    // this.testAPI();
  },
};
</script>

<style lang="scss">
@import '@/styles/style.scss';

#appDEBRAIN {
  font-family: 'Absolut Sans 2021';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1600px;
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  .invisible-scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
