<!-- eslint-disable vuejs-accessibility/iframe-has-title -->
<template>
  <div id="meetingSDKElement">
    <iframe :src="urlIframe" v-if="urlIframe" crossorigin="anonymous"></iframe>
  </div>
</template>

<script>

// import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

const KJUR = require('jsrsasign');

export default {
  props: ['objrsvp'],
  name: 'BannerVideoZoom',
  created() {

  },
  mounted() {
    /*
    ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
    */
    if (this.objrsvp) {
      this.userEmail = this.objrsvp.email;
      this.userName = `${this.objrsvp.firstname} ${this.objrsvp.lastname}`;
      this.meetingNumber = this.sessions[(this.objrsvp.session - 1)].meetingId ?? '';
      this.passWord = this.sessions[(this.objrsvp.session - 1)].passWord ?? '';

      // this.startMeeting();

      const signature = this.generateSignature(
        this.clientId,
        this.clientSecret,
        this.meetingNumber,
        0,
      );

      this.urlIframe = `https://wc.zoomembed.debrain.cloud/?signature=${signature}&username=${this.userName}
                      &useremail=${this.userEmail}&meetingid=${this.meetingNumber}&leaveurl=${this.leaveUrl}
                      &clientid=${this.clientId}&pwd=${this.passWord}`;
    }
  },
  data() {
    return {
      clientId: 'PkEKTDgTe6O6Z_rf2KfNw',
      clientSecret: 'iyCtYf3q2me1fRU3EaQmZuLCJG2K2PoL',
      meetingNumber: '',
      passWord: '',

      authEndpoint: '',
      role: 0,
      userName: 'Invitado',
      userEmail: 'aaaaa.nova@hellodebrain.com',
      registrantToken: '',
      zakToken: '',
      leaveUrl: 'https://wc.zoomembed.debrain.cloud/?thankyou=true',
      urlIframe: '',
      sessions: [
        {
          id: 1,
          dateStart: '2023-08-29T14:30:00Z',
          dateEnd: '2023-08-29T15:00:00Z',
          messageDate: 'August 29, 2023',
          messageTime: '11:30am ET / 01:00pm ',
          htmlDate: 'Thursday, August 29 2023',
          htmlTime: '11:30 AM - 01:00 PM',
          meetingId: '84996946037',
          passWord: 'eTBBem9YZGxyd1JDMzRvOVhzK0V3Zz09',
        },
        {
          id: 2,
          dateStart: '2023-08-29T:16:00:00Z',
          dateEnd: '2023-08-29T17:30:00Z',
          messageDate: 'August 29, 2023',
          messageTime: '02:00pm ET / 03:30pm ',
          htmlDate: 'Thursday, August 29 2023',
          htmlTime: '02:00 PM - 03:30 PM',
          meetingId: '81706748936',
          passWord: 'WXI4Vk1iZlhpazBpTS9YY3lqZkVkQT09',
        },
        {
          id: 3,
          dateStart: '2023-08-30T16:30:00Z',
          dateEnd: '2023-08-30T21:00:00Z',
          messageDate: 'August 30, 2023',
          messageTime: '03:30pm ET / 05:00pm ',
          htmlDate: 'Thursday, August 30 2023',
          htmlTime: '03:30 PM - 05:00 PM',
          meetingId: '84934902957',
          passWord: 'NTg4U2s3U25hMTVuT0ErNzNNNTRKQT09',
        },
      ],
    };
  },

  methods: {

    generateSignature(key, secret, meetingNumber, role) {
      console.log({
        key, secret, meetingNumber, role,
      });

      const iat = Math.round(new Date().getTime() / 1000) - 30;
      const exp = iat + 60 * 60 * 2;
      const oHeader = { alg: 'HS256', typ: 'JWT' };

      const oPayload = {
        sdkKey: key,
        appKey: key,
        mn: meetingNumber,
        role,
        iat,
        exp,
        tokenExp: exp,
      };

      const sHeader = JSON.stringify(oHeader);
      const sPayload = JSON.stringify(oPayload);
      const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret);
      return sdkJWT;
    },
    /*
    startMeeting() {
      const client = ZoomMtgEmbedded.createClient();
      const meetingSDKElement = document.getElementById('meetingSDKElement');

      const signature = this.generateSignature(
        this.clientId,
        this.clientSecret,
        this.meetingNumber,
        0,
      );
      client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        customize: {
          video: {
            isResizable: false,
            popper: {
              disableDraggable: true,
            },
            viewSizes: {
              default: {
                width: 1000,
                height: 600,
              },
              ribbon: {
                width: 300,
                height: 700,
              },
            },
          },
        },

      });

      client.join({
        signature,
        sdkKey: this.clientId,
        meetingNumber: this.meetingNumber,
        passWord: this.passWord,
        userName: this.userName,
        userEmail: this.userEmail,
        success: (success) => {
          console.log(success);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}

#meetingSDKElement {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

iframe{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

}
.footer__leave-btn{
  display: none !important;
}
</style>
