<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="cocktails_section">
    <div class="container_banner" >
    </div>
  </section>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  mounted() {
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.container_banner{
  background-image: url("https://plant.hellosandia.com/img/test/absolut/Banner1.jpg");
  width: 100%;
  height: 375px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 600px) {
  .container_banner{
    background-image: url("https://plant.hellosandia.com/img/test/absolut/Banner1Mobile.jpg");
    height: 210px;
  }
}

</style>
