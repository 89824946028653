<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="signupsection">
    <div class="container_signupsection" >
      <h1>SIGN-UP SUCCESS!</h1>
      <p v-if="activeCalendar">
        We look forward to seeing you during our live session.
        Use the button below to add the invite to your calendar
        and we’ll see you then.
      </p>
      <p v-if="!activeCalendar">
        ESTE ES EL MENSAJE
      </p>
    </div>

    <div class="container_datesession" v-if="activeCalendar">
      <div class="date_signup">
        <div>
          <img
          src="https://plant.hellosandia.com/img/test/absolut/calendar-lines-pen.svg"
          class="icon_calendar"
          alt=""
          />
        </div>
        <div class="text_date">
          <h3>DATE</h3>
          <p>
            {{session.htmlDate}}
            <br/>
            {{session.htmlTime}}
          </p>

        </div>
      </div>
      <div class="button_signup">
        <button @click="addCalendar"> ADD TO CALENDAR</button>
      </div>
    </div>

  </section>
</template>

<script>
import { ICalendar } from 'datebook';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

export default {
  components: {
  },
  data() {
    return {
      activeCalendar: false,
      sessions: [
        {
          id: 1,
          dateStart: '2023-08-29T15:30:00Z',
          dateEnd: '2023-08-29T17:00:00Z',
          messageDate: 'August 29, 2023',
          messageTime: '11:30am ET / 01:00pm ',
          htmlDate: 'Tuesday, August 29 2023',
          htmlTime: '11:30 AM - 01:00 PM',
          meetingId: '88419335458',
          passWord: 'VXJJdUpnL0lSVWp2WFp2d1ZpWml6QT09',
          passCode: 'Allyship1',
        },
        {
          id: 2,
          dateStart: '2023-08-29T18:00:00Z',
          dateEnd: '2023-08-29T19:30:00Z',
          messageDate: 'August 29, 2023',
          messageTime: '02:00pm ET / 03:30pm ',
          htmlDate: 'Tuesday, August 29 2023',
          htmlTime: '02:00 PM - 03:30 PM',
          meetingId: '81706748936',
          passWord: 'WXI4Vk1iZlhpazBpTS9YY3lqZkVkQT09',
          passCode: 'Allyship2',
        },
        {
          id: 3,
          dateStart: '2023-08-30T19:30:00Z',
          dateEnd: '2023-08-30T21:00:00Z',
          messageDate: 'August 30, 2023',
          messageTime: '03:30pm ET / 05:00pm ',
          htmlDate: 'Wednesday, August 30 2023',
          htmlTime: '03:30 PM - 05:00 PM',
          meetingId: '84934902957',
          passWord: 'NTg4U2s3U25hMTVuT0ErNzNNNTRKQT09',
          passCode: 'Allyship3',
        },
      ],
      session: {},
    };
  },
  computed: {

  },
  methods: {
    addCalendar() {
      const gAnalitycs = {
        value: 'Click_Add_Calendar',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      const rsvp = localStorage.getItem('safebarsrsvp');
      const url = `https://live-absolut-global-cms-website.pantheonsite.io/en-us/safe-bars-live-session/?rsvp=${rsvp}`;

      const description = `Thank you for registering in our Safe Bars Live Virtual Training.
      LIVE WEBINAR DATE: ${this.session.messageDate}
      LIVE WEBINAR TIME: ${this.session.messageTime}
      ACCESS LINK: ${url}
      PASSWORD:  ${this.session.passCode}
      Thank you and enjoy the training!
      `;

      const icalendar = new ICalendar({
        title: 'Safe Bars Live Virtual Training',
        description: description || '',
        location: url || '',
        start: new Date(moment(this.session.dateStart).format('Y-MM-DDTHH:mm:ssZ')),
        end: new Date(moment(this.session.dateEnd).format('Y-MM-DDTHH:mm:ssZ')),
      });

      /*
      const icalendar = new ICalendar({
        title: 'Jajajaja',
        description: description || '',
        location: '' || '',
        start: new Date(moment().format('Y-MM-DDTHH:mm:ssZ')),
        end: new Date(moment().format('Y-MM-DDTHH:mm:ssZ')),
      });
      */

      icalendar.download();
    },
    generateICS(eventData) {
      const {
        title, description, location, startDateTime, endDateTime,
      } = eventData;

      const start = this.formatDate(startDateTime);
      const end = this.formatDate(endDateTime);

      return `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nSUMMARY:${title}\nDESCRIPTION:${description}\nLOCATION:${location}\nDTSTART:${start}\nDTEND:${end}\nEND:VEVENT\nEND:VCALENDAR`;
    },
    downloadICS(filename, text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/calendar;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    formatDate(date) {
      const dateFromString = new Date(date);
      return dateFromString.toISOString().replace(/-|:|\.\d+/g, '');
    },
  },
  mounted() {
    const sessionId = localStorage.getItem('safebarssession');
    console.log(sessionId);
    this.session = this.sessions[sessionId - 1];
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#signupsection{
  max-width: 844px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding-inline: 25px;
  }

  .container_signupsection{
    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      max-width: 570px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 720px;
      margin: 0 auto;
      padding-bottom: 40px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }

    button{
      width: 348px;
      height:62px;
      background-color: #150075;

      color: #FFF;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
  }

  .container_datesession{
    .date_signup{
      display: grid;
      grid-template-columns: 100px 1fr;
      max-width: 400px;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      margin: 0 auto;
      text-align: start;

      @media screen and (max-width: 600px) {
        grid-template-columns: 100px 1fr;
        padding-inline: 20px;
      }

      .text_date{
        h3{
          margin: 0;
          padding: 0;
          color: #150075;
          font-family: 'Absolut Sans 2021' !important;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 131%; /* 28.82px */
          text-transform: uppercase;
        }
        p{
          margin: 0;
          padding: 0;
          color: #252525;
          text-align: inherit;
          font-family: 'Absolut Sans 2021' !important;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;

          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

      }
    }
    .button_signup{
      margin-top: 50px;
      button{
        width: 389px;
        height: 58px;
        background: #150075;

        color: #FFF;
        text-align: center;
        font-family: 'Absolut Sans 2021' !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 24.2px */
        text-transform: uppercase;
        margin-bottom: 137px;
        cursor: pointer;
        :hover{
          opacity: 0.8;
        }

        @media screen and (max-width: 600px) {
          width: 348px;
          max-width: 100%;
        }
      }
    }
  }

}
</style>
