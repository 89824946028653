<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="joinus_section" >
    <div class="container_joinus" >
      <h1>YOU’RE IN!</h1>
      <p>
        Thank you for signing up for the Safe Bars Webinar,
        the first step to your establishment receiving your
        Absolut Ally Safe Bar Certification.
        <br/>
        <br/>
        <b>
          Please note: Do not close your browser until you have gained access to the webinar page.
          There is no other access point.
          <br>
          You must watch the entire 35 minute webinar to move to the next step.
        </b>
      </p>
      <button @click="joinUS" class="join_us_button">WATCH WEBINAR NOW</button>
    </div>
  </section>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {
    joinUS() {
      const gAnalitycs = {
        value: 'Click_Watch_Webinar',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      this.$emit('changeStep', 3);
    },

  },
  mounted() {
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">

#joinus_section{
  max-width: 844px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding-inline: 25px;
  }

  .container_joinus{
    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 760px;
      margin: 0 auto;
      padding-bottom: 40px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    button {
      width: 348px;
      height:62px;
      background-color: #150075;
      color: #FFF;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      cursor: pointer;
      margin-bottom: 74px;
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
      :hover{
          opacity: 0.8 !important;
      }
    }
  }
}
</style>
