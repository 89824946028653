<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="thanks_participating">
    <div class="container_thanksparticipating" >
      <h1>THANKS FOR PARTICIPATING!</h1>
      <p>
        We hope you enjoyed the live event. It was a pleasure having you.
        <b>Your Absolut Ally Certification will be mailed to you soon.</b>
      </p>
    </div>
    <div class="container_followus">
      <h2> follow us</h2>
      <div class="followus_icons">
        <img
          src="https://plant.hellosandia.com/img/test/absolut/social/instagram.svg"
          class="icon icon_instagram"
          alt=""
          @click="locationHref('https://www.instagram.com/absolutvodka/','INSTAGRAM')"
          />
          <img
          src="https://plant.hellosandia.com/img/test/absolut/social/twitter.svg"
          class="icon icon_twitter"
          alt=""
          @click="locationHref('https://twitter.com/i/flow/login?redirect_after_login=%2Fabsolutvodka','TWITTER')"

          />
          <img
          src="https://plant.hellosandia.com/img/test/absolut/social/facebook.svg"
          class="icon icon_facebook"
          alt=""
          @click="locationHref(' https://www.facebook.com/Absolutcolombia/?brand_redir=217397088290363','FACEBOOK')"

          />
          <img
          src="https://plant.hellosandia.com/img/test/absolut/social/youtube.svg"
          class="icon icon_youtube"
          alt=""
          @click="locationHref('https://www.youtube.com/absolut','YOUTUBE')"

          />
      </div>
    </div>

  </section>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {
    locationHref(url, social) {
      const gAnalitycs = {
        value: `Click_Social_${social}`,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      window.open(url);
    },

  },
  mounted() {
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#thanks_participating{
  max-width: 844px;
  margin: 0 auto;

  .container_thanksparticipating{
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }

    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 524px;
      margin: 0 auto;
      padding-bottom: 40px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    button{
      width: 348px;
      height:62px;
      background-color: #150075;

      color: #FFF;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
  .container_followus{
    h2{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }
    .followus_icons{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 79px;
      .icon{
        padding: 13.5px;
        cursor: pointer;
      }
    }

  }
}
</style>
