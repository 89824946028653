<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="livewebinar_section">
    <div class="livewebinar_container" >
      <h1>Safe Bars Live Virtual Training</h1>
      <p>
        Thanks for being here. You can now access the live event below.
      </p>
    </div>
       <BannerVideoZoom :objrsvp="objrsvp" />
  </section>
</template>

<script>
import axios from 'axios';
import BannerVideoZoom from '@/components/BannerVideoZoom.vue';

export default {
  props: ['objrsvp'],
  components: {
    BannerVideoZoom,
  },
  data() {
    return {
    };
  },
  computed: {

  },
  methods: {
    async trackInitVideo() {
      const rsvp = localStorage.getItem('safebarsrsvp');
      await axios.get(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/zoom-started`, {})
        .then((_response) => { console.log(_response); }).catch((error) => {
          console.log(error);
        });
    },
    async trackEndvideo() {
      const rsvp = localStorage.getItem('safebarsrsvp');
      await axios.get(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/zoom-end`, {})
        .then((_response) => { console.log(_response); }).catch((error) => {
          console.log(error);
        });
    },
    endedVideo() {
      const rsvp = localStorage.getItem('safebarsrsvp');
      axios.post(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/webinar`, {}).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });

      this.$emit('changeStep', 7);
    },
  },
  mounted() {
    this.trackInitVideo();
    window.addEventListener('message', (e) => {
      const {
        data,
      } = e || {};
      console.log(data);
      if (data === 'HIDESECTION') {
        this.trackEndvideo();
        this.$emit('changeStep', 7);
      }
    }, false);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#livewebinar_section{

  .livewebinar_container{
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }

    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;
      text-transform: uppercase;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 430px;
      margin: 0 auto;
      padding-bottom: 40px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }

    }
    button{
      width: 348px;
      height:62px;
      background-color: #150075;

      color: #FFF;
      text-align: center;
      font-family: Absolut Sans 2021;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
}
</style>
