<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="form_section" style="--va-0-background-color: #150075;">
    <div class="container_form">
      <h1>SIGN-UP FORM</h1>
      <p>
        We’re calling on all bar and restaurant workers
        to join us for an empowering webinar hosted by
        Safe Bars. Attendees will gain essential insights
        into LGBTQ+ allyship and receive valuable guidance
        on fostering safe and inclusive hospitality spaces.
        <br />
        <br />
        Sign up now to secure your spot.
      </p>
    </div>
    <div class="form-container">
      <va-form class="va-form" tag="form" @submit.prevent="handleSubmit">
        <div class="two-columns">
          <div class="form-item"><label>Firstname</label>
            <va-input v-model="formData.firstname" outline  placeholder="Enter first name"/>
          </div>
          <div class="form-item"><label>Lastname</label>
            <va-input v-model="formData.lastname" outline placeholder="Enter last name" />
          </div>
          <div class="form-item"><label>Email</label>
            <va-input type="email" v-model="formData.email" outline placeholder="Enter email"/>
          </div>
          <div class="form-item"><label>Phone</label>
            <va-input v-model="formData.phone" outline :mask="phonemask"
            placeholder="Enter mobile phone"/>
          </div>
          <div class="form-item"><label>Address</label>
            <va-input v-model="formData.address" outline placeholder="Enter address"/>
          </div>
          <div class="form-item"><label>state</label>
            <va-select v-model="formData.state" :options="cleanStates" clearable outline searchable
              highlight-matched-text placeholder="Select state"/>
          </div>

          <div class="form-item"><label>Date of birth</label>
            <va-date-input v-model="formData.dob" outline clearable
            placeholder="Select date of birth"/>
          </div>

          <div class="form-item">
            <label>Name of establishment/Place of employment</label>
            <va-input v-model="formData.workplace" outline clearable
            placeholder="Enter first name"/>
          </div>
        </div>

        <div class="form-item">
          <va-checkbox v-model="formData.optin" class="mb-6"
            label="I consent to receive news and promotional information." />
        </div>
        <div  class="form-item privacy">
          <p>
            <b>Privacy Policy text.</b>  Your information may be used by Pernod Ricard
            and made available through us to any other entities of the Pernod
            Ricard Group to provide you with news and promotional information
            about Pernod Ricard. At any time, you can exercise your right of access,
            rectification, erasure, restriction, portability, objection or withdraw
            your consent at any time without affecting the lawfulness of processing
            based on your consent before withdrawal by contacting us at
            groupdpo@pernod-ricard.com. You also have a right to lodge a
            complaint with the Supervisory Authority. For more information,
            please consult our Online Privacy Policy.

          </p>

        </div>

        <va-button type="submit" class="mt-3">
          SEND
        </va-button>
      </va-form>

    </div>
  </section>
</template>

<script>
import { State } from 'country-state-city';
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
  },
  data() {
    return {
      phonemask: {
        numericOnly: true,
        blocks: [0, 3, 0, 3, 4],
        delimiters: ['(', ')', ' ', '-'],
      },
      stateList: null,
      formData: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        state: '',
        dob: null,
        workplace: '',
        optin: 1,
      },
    };
  },
  computed: {
    cleanStates() {
      let states = [];
      if (Array.isArray(this.stateList)) {
        states = this.stateList.map((state) => state.name);
      }
      return states;
    },
  },
  methods: {
    handleSubmit() {
      const gAnalitycs = {
        value: 'Click_Send_Form',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      const request = { ...this.formData };
      request.dob = moment(this.formData.dob).format('DD/MM/YYYY');

      const nacimiento = moment(this.formData.dob);
      const hoy = moment();
      const anios = hoy.diff(nacimiento, 'years');
      if (anios <= 21) {
        this.$swal('You must be at least 21 years old.');
        return;
      }
      axios.post('https://juice.hellosandia.com/attendees/absolut/add', this.formData).then((response) => {
        if (response.data.success) {
          console.log(response.data.data.rsvp);
          localStorage.setItem('safebarsrsvp', response.data.data.rsvp);
          this.$emit('changeStep', 2);
          return;
        }

        this.$swal(response.data.msg);
      }).catch((error) => {
        console.log(error);
      });
    },
    loadStates() {
      const stateList = State.getStatesOfCountry('US');
      this.stateList = structuredClone(stateList);
    },
  },
  beforeCreate() {
  },
  mounted() {
    this.loadStates();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
#form_section {
  max-width: 844px;
  margin: 0 auto;

  .form-container {
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }

    .va-input-wrapper {
      width: 100% !important;
    }

    .va-form {
      display: grid;
      gap: 15px;

      .two-columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      label {
        display: block;
        text-align: left;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      .va-input-wrapper {
        width: 100% !important;

        .va-input-wrapper__field {
          border: 1px solid #150075;
        }
      }
    }

    .form-item {
      text-align: left;

      label {
        font-size: 14px;
      }
    }
  }
  .container_form{
    padding-top: 14px;
    padding-bottom: 80px;
    @media screen and (max-width: 600px) {
      padding-inline: 25px;
    }
    h1{
      color: #150075;
      text-align: center;
      font-family: 'Absolut Handwritten Web' !important;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;

      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p{
      color: #252525;
      text-align: center;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      max-width: 844px;
      margin: 0 auto;

      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
  }

  #form_section .form-container .va-form label{
    color: #252525;
    font-family: 'Absolut Sans 2021' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 15.4px */
    text-transform: uppercase;
  }

  .va-checkbox__input-container {
    label{
      text-transform: capitalize !important;
    }
  }

  .va-select-content__placeholder{
    color: #84888C;
    font-family: 'Absolut Sans 2021' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
  .va-input-wrapper__text input, .va-input-wrapper__text textarea{
    &::placeholder{
      color: #84888C;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
  }

  .privacy{
    p{
      color: #252525;
      font-family: 'Absolut Sans 2021' !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      b{
        color: #150075;
        font-family: 'Absolut Sans 2021' !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
        text-transform: uppercase;
      }
    }
  }

  .va-button--normal{
    --va-0-background-color: #150075 !important;
    height: 62px;
    color: #FFF !important;
    font-family: 'Absolut Sans 2021' !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 110% !important; /* 30.8px */
    text-transform: uppercase !important;
    margin-bottom: 140px;
  }

}
</style>
