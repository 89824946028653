<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<!-- eslint-disable vuejs-accessibility/iframe-has-title -->
<template>
  <div class="sdk-btm__video" data-ga="YOUTUBE VIDEO" data-ga-event="VIDEO PLAYS"
    data-ga-value="View Cocktail Video Clicks Play Button">

    <video width="250" class="logo_malibu" playsinline muted loop autoplay @click="clickVideo"
      @keypress.enter="clickVideo" v-if="!isYoutube">
      <source :src="video" type="video/mp4">
    </video>

    <div class="youtube-container" v-if="isYoutube"
      @click="clickIframe" @keypress.enter="clickIframe">

      <button class="playbutton" @click="playVideoManually" v-if="buttonplay">
        <img src="https://plant.hellosandia.com/img/test/absolut/play_icon.png" alt="playbutton" />
      </button>

      <vue-plyr ref="plyr" :options="videoOptions">
        <div class="plyr__video-embed">
          <iframe
            :src="video"
            allowfullscreen
            allowtransparency
            allow="autoplay;"
            frameborder="0"
          ></iframe>
        </div>
      </vue-plyr>

    </div>

    <img :src="logo_malibu" alt="" :class="iconMobile ? 'logo_malibu' : 'logo_malibu d-none-mobile'"
      :style="!icon ? 'display:none;' : ''" />

  </div>
</template>

<script>

import VuePlyr from '@skjnldsv/vue-plyr';
import axios from 'axios';

const VUE_APP_S3 = 'https://plant.hellosandia.com/img/test/';

export default {
  components: { VuePlyr },
  props: [
    'video',
    'text',
    'isYoutube',
    'icon',
    'iconMobile',
  ],
  data() {
    return {
      html5Clicked: false,
      music: `${VUE_APP_S3}music.png`,
      logo_malibu: `${VUE_APP_S3}logo_malibu.svg`,
      selectedState: null,
      videoOptions: {
        autoplay: false,
        autopause: false,
        loadSprite: true,
      },
      buttonplay: true,
    };
  },
  methods: {
    async trackInitVideo() {
      const rsvp = localStorage.getItem('safebarsrsvp');
      await axios.get(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/vimeo-started`, {})
        .then((_response) => { console.log(_response); }).catch((error) => {
          console.log(error);
        });
    },
    async trackEndvideo() {
      const rsvp = localStorage.getItem('safebarsrsvp');
      await axios.get(`https://juice.hellosandia.com/attendees/absolut/${rsvp}/vimeo-end`, {})
        .then((_response) => { console.log(_response); }).catch((error) => {
          console.log(error);
        });
    },
    ended() {
      this.trackEndVideo();
      this.$emit('ended');
    },
    ready() {
      this.$emit('ready');
    },
    play() {
      this.trackInitVideo();
      this.$emit('play');
    },
    playVideoManually(e) {
      e.preventDefault();
      this.buttonplay = false;
      this.$refs.plyr.player.play();
      const gAnalitycs = {
        value: 'Click_Play Button-UK',
        event_action: 'Click_Play Button-UK',
        event_category: 'Video Button Play',
        event_label: `Play Button${this.video}`,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
    },
    clickIframe() {
      console.log('click!');
    },
    clickVideo() {
      if (this.html5Clicked) {
        return;
      }
      this.html5Clicked = true;
      const gAnalitycs = {
        value: 'Click_Play Button-UK',
        event_action: 'Click_Play Button-UK',
        event_category: 'Video Button Click',
        event_label: `Play Button${this.video}`,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
    },

    trackIframeClick() {
      const message = this.$refs.customiframe;
      window.focus();

      window.addEventListener('blur', () => {
        if (this.html5Clicked) {
          return;
        }
        this.html5Clicked = true;
        setTimeout(() => {
          console.log('Click Iframe');
          const gAnalitycs = {
            value: 'Click_Play Button-UK',
            event_category: 'Video Button Click',
            event_label: `Play Button${this.video}`,
            event_action: 'Click_Play Button-UK',
          };

          if (document.activeElement.tagName === 'MALIBU-PINA-COLADA') {
            message.textContent = `clicked ${Date.now()}`;
            this.$gtag.event(gAnalitycs.value, gAnalitycs);
          }
          // if (document.activeElement.tagName === 'IFRAME') {
          //   message.textContent = `clicked ${Date.now()}`;
          //   console.log('clicked');
          // }
        });
      }, { once: false });
    },
  },
  mounted() {
    this.html5Clicked = false;

    if (this.isYoutube) {
      this.trackIframeClick();
    }
    this.$refs.plyr.player.on('ready', this.ready);
    this.$refs.plyr.player.on('ended', this.ended);
    this.$refs.plyr.player.on('play', this.play);
  },

};
</script>

<style lang="scss">

@import url('https://cdn.plyr.io/3.7.8/plyr.css');

.logo_malibu {
  position: absolute;
  top: 25px;
  left: 40px;

  @media screen and (max-width: 600px) {
    right: 30%;
    left: auto;

  }
}

.container_description_banner {
  position: absolute;
  top: 0;
  z-index: 9999;
  display: grid;
  align-content: center;
  justify-content: start;
  align-items: start;
  justify-items: start;
  text-align: initial;
  top: 50%;
  bottom: 50%;
  left: 10%;

  h2 {
    font-family: 'CubanoItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    color: #07C0B4;
    max-width: 768px;
  }

  p {
    font-family: 'geoxe3';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    color: #23161B;
    max-width: 449px;
  }
}

@media screen and (max-width: 1200px) {
  .container_description_banner {
    h2 {
      font-size: 49px;
      line-height: 43px;

    }
  }
}

@media screen and (max-width: 900px) {

  .container_description_banner {
    padding: 20px;
    justify-content: center;
    left: auto;
    right: auto;

    h2 {
      font-size: 36px;
      font-size: 36px;
      line-height: 43px;
      text-align: center;
    }

    img {
      margin: 0 auto;
    }

    p {
      text-align: center;
      font-size: 18px;
      margin: 0 auto;
      max-width: 270px;
    }
  }
}

.sdk-btm__video {
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    .iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
    }
  }
}

.sdk-btm__video {
  position: relative !important;
  width: 100% !important;
  /*padding-bottom: 56.25% !important;*/
  padding-top: 0 !important;
  padding-bottom: 25px;

  @media screen and (max-width: 600px) {
  padding-bottom: 56.25% !important;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.d-none-mobile {
  background-color: red;
  display: none;
}

.plyr__controls, .plyr__control {
    display: none !important;
  }
  .youtube-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    border: 0;
    iframe, video, object {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      border: 0;
    }
  }

  .playbutton{
    cursor: pointer;
    z-index: 10000;
    position: absolute;
    background: transparent;
    border: transparent;
    width: 100%;
    height: 100%;
    left: 50%;
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &:hover, &:active {
      opacity: 0.8;
    }
    img{
      margin: 0 auto;
      height: 70px;
      @media screen and (min-width: 600px) {
        height: 100px;
      }
    }
  }
</style>
