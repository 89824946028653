<!-- eslint-disable max-len -->
<template>
  <div class="sdk-main">
    <div data-page="bornToMix">

      <BannerImageSection  v-if = "step != 0" />
      <FormSection v-if = "step == 1" @changeStep="changeStep"/>
      <JoinUSSection v-if = "step == 2" @changeStep="changeStep"/>
      <WebinarSection v-if = "step == 3" @changeStep="changeStep"/>
      <ThankYouSessions v-if = "step == 4" @changeStep="changeStep"/>
      <SignUpSection v-if = "step == 5" @changeStep="changeStep"/>
      <LiveWebinarSection v-if = "step == 6 && objrsvp"  @changeStep="changeStep" :objrsvp="objrsvp"/>
      <ThanksParticipating v-if = "step == 7" @changeStep="changeStep"/>

    </div>
  </div>
</template>

<script>

import axios from 'axios';

import BannerImageSection from '@/components/BannerImageSection.vue';
import FormSection from '@/components/FormSection.vue';
import JoinUSSection from '@/components/JoinUSSection.vue';
import WebinarSection from '@/components/WebinarSection.vue';
import ThankYouSessions from '@/components/ThankYouSessions.vue';
import SignUpSection from '@/components/SignUpSection.vue';
import LiveWebinarSection from '@/components/LiveWebinarSection.vue';
import ThanksParticipating from '@/components/ThanksParticipating.vue';

export default {
  name: 'HomeView',
  components: {
    BannerImageSection,
    FormSection,
    JoinUSSection,
    WebinarSection,
    ThankYouSessions,
    SignUpSection,
    LiveWebinarSection,
    ThanksParticipating,
  },
  data() {
    return {
      step: 0,
      rsvp: null,
      initialStep: 1,
      objrsvp: null,

    };
  },
  methods: {
    changeStep(step) {
      this.step = step;
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Si deseas una animación suave, usa "smooth", de lo contrario, usa "auto" o simplemente omite esta propiedad.
      });
    },
    getQueryParams() {
      const url = window.location.href;
      const paramArr = url.slice(url.indexOf('?') + 1).split('&');
      const params = {};
      // eslint-disable-next-line array-callback-return
      paramArr.map((param) => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
      });
      return params;
    },
    async getRSVP() {
      // eslint-disable-next-line no-return-await
      return await axios.get(`https://juice.hellosandia.com/attendees/absolut/${this.rsvp}`, {})
        .then((response) => response?.data ?? {}).catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    const queryParams = await this.getQueryParams();
    this.rsvp = queryParams.rsvp;
    localStorage.setItem('safebarsrsvp', this.rsvp);
    let validationRSVP = null;
    if (this.rsvp) {
      const { success, data } = await this.getRSVP();
      validationRSVP = success;
      this.objrsvp = data[0] ?? {};
    }

    const url = await window.location.href;
    const result = url.includes('safe-bars-webinar');

    if (this.rsvp && validationRSVP && !result) {
      await this.changeStep(6);
      return;
    }

    if (!validationRSVP && this.rsvp && !result) {
      await this.changeStep(0);
      return;
    }

    if (!result) {
      await this.changeStep(0);
      this.changeStep(0);
      // return;
    }

    await this.changeStep(this.initialStep);
  },

};
</script>

<style lang="scss">
.sdk-main{
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

</style>
